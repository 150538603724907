/*.accordion-body {*/
/*  background-color: dimgrey;*/
/*  !*color: darkgreen; <- font color*!*/
/*}*/
.accordion-body {
    background-color: #f0f0f0;
}

.accordion-button {
    color: slategrey; /* Change to your desired color */
    background-color: lightgrey; /* Change to your desired background color */
    border: none;
}

/* Change the color of the Accordion headers when active */
.accordion-button:active {
    color: gray !important; /* Change to your desired color */
    background-color: darkorange !important; /* Change to your desired background color */
    border-color: deeppink !important;
    /* Add other styles as needed */
}

/* Change the color of the Accordion headers when selected */
.accordion-button:focus {
    color: lightgrey !important; /* Change to your desired color */
    background-color: slategrey !important; /* Change to your desired background color */
    border-color: deeppink !important;

    /* Add other styles as needed */
}

.accordion-button:not(.collapsed) {
    color: lightgrey; /* Change to your desired color */
    background-color: slategrey; /* Change to your desired background color */

    /* Add other styles as needed */
}

