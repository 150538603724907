.login .container {
    position: relative;
    background-image: url('../../../public/can-do.gif');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}

/*.product-page {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: lightgrey;*/
/*    opacity: 0.8;*/
/*}*/