body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f0f0f0; /* Change to your desired background color */
  padding: 5px;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
}

.message {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  max-width: 70%;
  text-align: left; /* Updated to ensure left alignment */
}

.user {
  background-color: lightblue;
  color: dimgray;
  align-self: flex-end;
  margin-left: auto;
}

.ai {
  background-color: dimgray;
  color: white;
}
